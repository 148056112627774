<script setup>
import { useStore } from 'vuex'
const $store = useStore()
const companyId = $store.state.session.company.company_id
const services = [
  {
    title: 'Lead Forms',
    to: 'lead_rotations',
    icon: 'house-flag',
    subtitle: `Pipe your new leads right into Bolster with a single link.`,
    desc: `Plaster it all over your website, socials and business cards -- or generate a QR Code for your marketing materials. Meet your customers where they are.`
  },
  {
    title: 'Campaign Tracker',
    to: 'lead_sources',
    icon: 'flag-swallowtail',
    tag: 'New!',
    subtitle: `Track your marketing ROI by lead source.`,
    desc: `Bad marketing can crush your business with an expense you will never get back. Track your marketing campaigns by lead source, and improve your marketing ROI over time.`
  },
  {
    title: 'Showcase',
    to: `bolster_showcase/${companyId}`,
    tag: 'New',
    icon: 'globe-pointer',
    subtitle: `You're exceptional -- let Bolster tell the world.`,
    desc: `Your Bolster showcase is your most performant landing page. Every Bolster contractor gets this public page that will boost your SEO for free and generate leads from your best before/after shots, long-form descriptions, and your 100%
            validated customer reviews.`
  },
  {
    title: 'Open Quote',
    to: `bolster_showcase/${companyId}`,
    tag: 'New!',
    icon: 'shop',
    subtitle: `Become an e-commerce powerhouse!`,
    desc: `3x your qualified opportunities -- turn your assembly templates into guided step-by-step wizards. Your customers can build their own estimates and you get ssssuper-qualified leads, ready to buy, delivered right to your pipeline.`
  }
]

const to = (link) => $store.dispatch('to', link)
</script>

<template>
  <Container size="5xl">
    <Page title="Marketing">
      <div class="w-full grid md:grid-cols-2 gap-1 pb-8">
        <div
          class="basis-[400px] shrink-1 p-2 relative"
          v-for="service in services"
          :key="service.title"
        >
          <div
            @click="to(service.to)"
            class="border border-surface-300 flex flex-col justify-start items-stretch rounded-md p-10 gap-4 h-full w-full hover:border-pitch-black hover:ring hover:ring-pitch-black cursor-pointer"
          >
            <div
              class="p-4 rounded-md flex justify-center items-center bg-pitch-black text-level-yellow w-14"
            >
              <font-awesome-icon :icon="service.icon" size="xl" />
            </div>
            <div class="flex flex-col gap-1">
              <span class="text-2xl leading-tight font-medium tracking-wide">{{
                service.title
              }}</span>
              <span class="text-lg">{{ service.subtitle }}</span>
            </div>

            <span class="text-base text-cool-gray-700">
              {{ service.desc }}
            </span>

            <div class="flex w-full h-full items-end relative mt-2">
              <Btn severity="tertiary" size="lg" @click="to(service.to)" v-if="service.to">
                Get started
                <font-awesome-icon icon="arrow-right" />
              </Btn>
            </div>

            <div class="flex justify-start items-end absolute top-12 right-8">
              <Tag class="!text-base" :severity="service.tagSeverity ?? 'info'" v-if="service.tag">
                {{ service.tag }}
              </Tag>
            </div>
          </div>
        </div>
      </div>
    </Page>
  </Container>
</template>
